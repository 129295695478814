import {
  type LinkProps,
  useNavigate as useRemixNavigate
} from '@remix-run/react'
import { useLocale } from '~/hooks/useLocale'
import {
  matchIsLinkToStartWithLocalePrefix,
  setLocalePrefixToLinkTo
} from '~/utils/i18n-link'

export function useNavigate() {
  const locale = useLocale()
  const navigate = useRemixNavigate()

  return (
    to: LinkProps['to'],
    options?: {
      replace?: boolean
      preventScrollReset?: boolean
    }
  ) => {
    if (matchIsLinkToStartWithLocalePrefix(to, locale)) {
      return navigate(to, options)
    }

    return navigate(setLocalePrefixToLinkTo(to, locale), options)
  }
}
